import context from "../../api/context";

export const state = {
    isLoading: false,
    context: {}
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    SET_CONTEXT(state, context) {
        state.context = context;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    context: (state) => {
        return state.context;
    }
};

export const actions = {
    loadContext({ commit }) {
        commit('START_LOADING');
        context.getContext().then(r => {
            commit('STOP_LOADING');
            commit('SET_CONTEXT', r.data);
        }).catch(e => {
            console.log(e);
            commit('STOP_LOADING');
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}