<template>
  <router-view />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Welcome",
      // all titles will be injected into this template
      titleTemplate: "%s | MAIA Admin",
    };
  },
  computed: {
    ...mapGetters("auth", {
      loggedIn: "loggedIn",
    }),
  },
  methods: {
    ...mapActions("context", {
      loadContext: "loadContext",
    }),

    getRouterParams() {
      // console.log(this.$router.currentRoute.query);
    },
  },
  created() {
    // const mq = window.matchMedia("(prefers-color-scheme: dark)");
    // mq.addEventListener("change", e => {
    //   console.log(mq);
    //   this.$vuetify.theme.dark = e.matches;
    // });
    this.getRouterParams();
    if (this.loggedIn) {
      this.loadContext();
    }
  },
};
</script>
