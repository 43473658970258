import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import themeConfig from './modules/themeConfig'
import context from './modules/context'
import auth from './modules/auth'
import verticalSidebar from './modules/verticalSidebar'

// Load Vuex
Vue.use(Vuex)

// Vuex Storage
import VuexPersist from 'vuex-persist';

const vuexStorage = new VuexPersist({
  key: 'maia-admin-auth',
  storage: window.localStorage,
  modules: ['auth']
})

// Create store
export default new Vuex.Store({
  plugins: [vuexStorage.plugin],
  modules: {
    largeSidebar,
    compactSidebar,
    themeConfig,
    verticalSidebar,
    auth,
    context
  },
})
