// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'
import { themePreset } from "../../src/themeConfig";

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

Vue.use(VuetifyToast, { 
  x: 'center',
  y: 'top'
})

export default new Vuetify({
  theme: themePreset.theme,
  rtl: themePreset.rtl
});
