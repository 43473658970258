/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login",
      redirect: "/app/sessions/sign-in",
    },
    {
      path: "/app/sessions",
      component: () => import("@/views/app/sessions/Sessions"),

      children: [
        {
          path: "sign-in",
          name: "login",
          component: () => import("@/views/app/sessions/SignIn"),
          meta: {
            guest: true
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      meta: {
        auth: true,
        setup: true,
        verified: true
      },
      redirect: "/dashboard",

      children: [
        // {
        //   path: "layout/:layout",
        //   component: () => import("@/views/app/Index")
        // },

        {
          path: "dashboard",
          // redirect: "/investors"
          component: () => import("@/views/app/pages/Dashboard")
        },
        {
          path: "invites",
          component: () => import("@/views/app/pages/Invites")
        },
        {
          path: "approvals",
          component: () => import("@/views/app/pages/Approvals")
        },
        {
          path: "approvals/:id",
          component: () => import("@/views/app/pages/Approvals/Show")
        },
        {
          path: "investors",
          component: () => import("@/views/app/pages/Investors")
        },
        {
          path: "investors/:id",
          component: () => import("@/views/app/pages/Investors/Show")
        },
        {
          path: "products",
          component: () => import("@/views/app/pages/Products")
        },
        {
          path: "products/create",
          component: () => import("@/views/app/pages/Products/Create")
        },
        {
          path: "products/:id",
          component: () => import("@/views/app/pages/Products/Show")
        },
        {
          path: "settings",
          component: () => import("@/views/app/pages/Settings")
        },
        {
          path: "invoice",
          component: () => import("@/views/app/pages/Invoice")
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    // store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

// Wait for Vuex Persist to do it's thing
const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  next()
}
router.beforeEach(waitForStorageToBeReady)

// Guest Guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guest)) {
    if (!store.getters['auth/loggedIn']) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

// Auth Guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters['auth/loggedIn']) {
      next()
      return
    }
    next('/app/sessions/sign-in?to=' + to.path)
  } else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.

  // setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  // setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);

  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
